/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Content Template Styles ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	.main-content {
		overflow-x: hidden;

		button {
			&.btn-branded {
		        color:$lightblue2;
		        &:hover {
		        	color:$darkgray;
		        }
			}
		}

		.btn-branded {
			border: 1px solid $lightblue2;
			border-radius: 3rem;
			border-top-left-radius: 0;
			font-weight: $font-weight-base;
			font-family: $font-nimbus-extended;
			position: relative;
	        padding-top: .75rem;
	        padding-bottom: .75rem;
	        padding-right: crunch-spacer(6);
	        min-width: 9.375rem;
	        display: inline-flex;
	        justify-content: center;
	        align-items: center;
	        text-transform: uppercase;
	        cursor: pointer;

	        @include fluid-margin(top,5);
	        @include fluid(padding-left, crunch-spacer(4), crunch-spacer(5));
	        @extend %font-size-12,
	        		%letter-spacing-100,
	        		%single-transition;

	        &::before,
	        &::after {
	        	content: "";
				position: absolute;
				right: 1.875rem;
				width: .55rem;
				height: .55rem;
				top: 50%;
				transform: translateY(-50%) rotate(-90deg);


				@extend %single-transition;
	        }

	        &::after {
				background: url("../img/icon__chevron-down-small--darkblue.svg") no-repeat center;
				background-size: contain;
				opacity: 1;
				z-index: 1;
	        }

	        &::before {
	        	position: absolute;
				background: url("../img/icon__chevron-down-small--white.svg") no-repeat center;
				background-size: contain;
				opacity: 0;
				z-index: -1;
	        }

	       	&:hover,
	       	&:focus {
				background: $lightblue3;
				border-color: $lightblue3;
				box-shadow: none;

				&::after {
					opacity: 0;
					z-index: -1;
				}

				&::before {
					z-index: 1;
					opacity: 1;
				}
	       	}

	       	&--white {
	       		border-color: $white;
				background: $white;

				&:hover,
				&:focus {
					border-color: $lightblue3;
					background: $lightblue3;
					color: $mediumblue;
				}
	       	}

	       	&--text-only {
		   		border-color: $white;
		   		background: transparent;
		   		color: $darkgray;

		   		@extend %font-size-12;

		   		&:hover,
		   		&:focus {
		   			color: $lightblue2;
					background: transparent;
					border-color: $white;
					box-shadow: none;

					&::after {
						opacity: 1;
						z-index: 1;
					}

					&::before {
						z-index: -1;
						opacity: 0;
					}
		   		}
		   	}

		   	&.text-white {
		   		&:hover,
		   		&:focus {
		   			color: #292d4a !important;
		   		}
		   	}
		}

		&__title {
			color: $lightblue2;
		}
	}

	.single,
	.page {
		.main-footer {
			display: block;
		}
	}

	.post-content {
		flex-basis: 100%;
	}

	.entry-content {
		.first-element-fix {
            & + *,
            & + p > a > img,
            & + p > img,
            & + div.list-styles > ul,
            & + div.list-styles > ul > li:first-of-type,
            & + .lead {
                margin-top: 0 !important;
            }
        }
	}

	.post-content,
	.entry-content {
		border-top: 1px solid #eee;
		color: #313131;

	    h2,
        h3,
        h4,
        h5,
        h6,
        .heading {
            @include fluid(margin-top, .75, 1.75em);
        }

		p {
			font-weight: $font-weight-base;
			line-height: 1.75;

            @include fluid(margin-top, .5, 1.5em);

            > a[class*='-button'] {
                @include fluid(margin-top, .5, 1em);
            }

            sup {
            	font-size: 0.75em;
            }
        }

        a {
        	color: $lightblue2 !important;

			@extend %single-transition;

        	&:hover,
        	&:focus {
        		color: $darkgray !important;
        	}
        }

        ul:not(.wp-block-gallery) {
            list-style-type: none;

            li {
                &::before {
					content: "\2022";
					color: $lightblue2;
					font-weight: bold;
					font-size: 2rem;
					display: inline-block;
					width: 1.7em;
				    margin-left: -1.5rem;
				    font-size: 1.3em;
				    position: absolute;
				    line-height: 1em;
                }
            }
        }

        ol:not(.wp-block-gallery) {
            list-style-type: decimal;
        }

        ul:not(.wp-block-gallery),
        ol:not(.wp-block-gallery) {
            list-style-position: outside;
            margin-right: 0;
            margin-bottom: 0;
            font-weight: $font-weight-base;

            @include fluid((
                margin-top: (
                    min: .5,
                    max: 1.125em,
                ),
                margin-left: (
                    min: .5,
                    max: 2.5em,
                ),
            ));

            ul,
            ol {
                list-style-position: outside;
                margin-right: 0;

                @include fluid((
                    margin-top: (
                        min: .5,
                        max: 1em,
                    ),
                    margin-left: (
                        min: .5,
                        max: 1em,
                    ),
                    margin-bottom: (
                        min: .5,
                        max: 1.5em,
                    ),
                ));
            }

            ul {
                list-style-type: none;
            }

            ol {
                list-style-type: lower-latin;
            }

            li {
                @include fluid((
                	margin-top: (
                		min: .5,
                		max: .875em,
                	),
                	padding-left: (
                		min: .5,
                		max: 1.5em,
                	),
                ));

                &:first-child {
                    margin-top: 0;
                }
            }
        }

    	blockquote {
    		background: $lightgray;
    		text-align: left;
    		position: relative;

    		@include fluid((
    			padding-y: (
    				min: 1rem,
    				max: 2rem,
    				important: true,
    			),
    			padding-x: (
    				min: 2.5rem,
    				max: 4.5rem,
    				important: true,
    			),
    			border-radius:(
    				min: .5,
    				max: 3.125rem,
    			)
    		));

    		border-top-left-radius: 0 !important;

    		@include fluid-margin( y, 7);

    		&::before {
    			content: "";
    			position: absolute;
    			background: url("../img/icon__quote.svg") no-repeat center;
    			background-size: contain;

    			@include fluid((
    				width: (
    					min: .7,
    					max: 1.5rem,
    				),
    				height: (
    					min: .7,
    					max: 1.5rem,
    				),
    				top: (
    					min: .75rem,
    					max: 1.5rem
    				),
    				left: (
    					min: .75rem,
    					max: 1.5rem,
    				),
    			));
    		}

    		p {
    			color: $darkbluegray;
    			line-height: 1.3 !important;
    			font-style: normal !important;
    			margin-top: 0 !important;

    			@extend %font-size-24;
    		}

    		cite {
    			display: block;
    			color: $lightblue2 !important;
    			font-style: normal;
    			text-align: left !important;
    			font-size: .875rem !important;

    			@include fluid-margin(top,4);
    		}
    	}
	}

	.meta-data {
		.single-meta{
			&__key {
				font-family: $font-nimbus;
				color: $darkgray;
			}

			&__value {
				color: $darkgray;
				.value {
					&--colored {
						color: $lightblue2;
					}
				}

			}

			&__divider {
				color: $lightblue2;
			}

			&--full {
				flex: 100%;
			}
		}

		.btn {
			margin-top: 0 !important;
		}
	}

	.content-links {
		.btn-branded {
			margin-top: 0 !important;
			/*padding: 0;*/
		}

		&__btn {
			&::after {
				display: inline-block;
			}
		}

		&__btn-divider {
			color: $lightblue2;
			font-weight: $font-weight-bold;
		}
	}

	.hero-section {
		&__wrapper {
			@include fluid(height, .5, 30rem);
		}

		&__image {
			@include fluid(border-top-right-radius, .5, 7.5rem);
		}

		&__search {
			background: url("../img/bg__search.png") no-repeat $mediumblue;
			background-size: auto 100%;
		    background-position: 0%;

		    @include media-breakpoint-up(sm) {
		    	background-position: 100%;
		    }

		    form.search-form {
		    	input[type=text] {
					border-color: $lightblue3 !important;
					background: $mediumblue !important;

					&:focus {
						background:#fff !important;
						color: $darkgray;
						outline:none;
						border-color: #fff !important;

						&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
							color:$darkgray;
						}

						&::-moz-placeholder { /* Firefox 19+ */
							color:$darkgray;
						}

						&:-ms-input-placeholder { /* IE 10+ */
							color:$darkgray;
						}

						&:-moz-placeholder { /* Firefox 18- */
							color:$darkgray;
						}
					}
		    	}

		    	i {
		    		color: $lightblue3 !important;
		    	}

		    	.suggestions {
		    		a {
						&:hover,
						&:focus {
							color: $white !important;
							text-decoration: none !important;
							border-color: transparent !important;
						}
		    		}
		    	}
		    }

			.search-link {
				margin-top: 0 !important;
				border: none;
				padding-left: 0 !important;
				text-align: left;
				font-size: .8125rem !important;

				&::after {
					display: inline-block;
				}

				&:hover,
				&:focus {
					color: $lightblue3 !important;
					background: transparent;
					border: none;

					&::after {
						opacity: 0 !important;
						z-index: -1 !important;
					}

					&::before {
						z-index: 1 !important;
						opacity: 1 !important;
					}
				}
				&.btn-branded.text-white:hover {
					color: $lightblue3 !important;
				}
			}
		}
	}

	/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
	/* ~~~~~~~~~~ Blocks Template Styles ~~~~~~~~~~ */
	/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	.wp-block-lazyblock-accordion {
		.card {
			border-radius: 2.125rem !important;
			border: 1px solid $lightblue2 !important;
			background: transparent;
			border-top-left-radius: 0 !important;

			@extend %single-transition;
			@include fluid-margin(top,5);

			.card-header {
				border: 0;
				background: transparent;
				padding: 0;

				h2 {
					.btn {
						display: block;
						font-family: $font-nimbus-extended;
						color: $lightblue2;
						width: 100%;
						text-align: left;
						text-transform: uppercase;
						position: relative;
						font-weight: $font-weight-base;

						@include fluid((
							padding-y: (
								min: .5rem ,
								max: 1.25rem,
							),
							padding-x: (
								min: 1.25rem,
								max: 1.5rem,
							),
						));
						@extend %font-size-16,
								%single-transition,
								%letter-spacing-100;

						&[aria-expanded="true"] {
							&::before,
							&::after {
								transform: rotate(-180deg);
								margin-top: -2px;
							}
						}

						&::before,
						&::after {
							content: "";
							position: absolute;
							right: 1.5rem;
							transform: rotate(0);

							@extend %single-transition;
							@include fluid((
								width: (
									min: .75,
									max: 1.25rem,
								),
								height: (
									min: .75,
									max: 1.25rem,
								),
								top: (
									min: 1rem,
									max: 1.25rem,
								),
							));
						}

						&::before {
							background: url("../img/icon__chevron-down--darkblue.svg") no-repeat center;
							background-size: contain;
							opacity: 1;
							z-index: 1;
						}

						&::after {
							background: url("../img/icon__chevron-down--white.svg") no-repeat center;
							background-size: contain;
							opacity: 0;
							z-index: -1;
						}

						&:focus {
							text-decoration: none;
						}

						&:hover {
							text-decoration: none;

							&::before {
								opacity: 0;
								z-index: -1;
							}

							&::after {
								opacity: 1;
								z-index: 1;
							}
						}
					}
				}
			}

			.card-body {
				color: $darkbluegray;
				background: $white;

				@include fluid((
					padding-top: (
						min: .5rem ,
						max: 1rem,
					),
					padding-x: (
						min: 1.25rem,
						max: 1.5rem,
					),
					padding-bottom: (
						min: 1.25rem,
						max: 1.5rem,
					),
				));
			}

			&:hover,
			&:focus {
				border-color: $lightblue3 !important;
				background: $lightblue3;
				color: $darkbluegray;

				.card-header {
					h2 {
						.btn {
							color: $mediumblue;
						}
					}
				}
			}
		}
	}

	.wp-block-pullquote {
		display: inline-block;

		@include fluid-margin(y, 7);

		blockquote {
			background: $lightgray;
			text-align: left;
			position: relative;

			@include fluid((
				padding-y: (
					min: 1rem,
					max: 2rem,
				),
				padding-x: (
					min: 2.5rem,
					max: 4.5rem,
				),
				border-radius:(
					min: .5,
					max: 3.125rem,
				)
			));

			border-top-left-radius: 0 !important;

			&::before {
				content: "";
				position: absolute;
				background: url("../img/icon__quote.svg") no-repeat center;
				background-size: contain;

				@include fluid((
					width: (
						min: .7,
						max: 1.5rem,
					),
					height: (
						min: .7,
						max: 1.5rem,
					),
					top: (
						min: .75rem,
						max: 1.5rem
					),
					left: (
						min: .75rem,
						max: 1.5rem,
					),
				));
			}

			p {
				color: $darkbluegray;
				line-height: 1.3;

				@extend %font-size-24;
			}

			cite {
				display: block;
				color: $lightblue2;
				font-style: normal;

				@include fluid-margin(top,4);
				@extend %font-size-14;
			}
		}
	}

	.wp-block-lazyblock-two-column-callout {
		position: relative;

		@include fluid(padding-y, .75rem, 1rem,xs,md);
		@include fluid(padding-y, 1rem, 2rem,lg);
		@include fluid-margin(y, 7);

		.single-column {
			height: 100%;
			position: relative;
			z-index: 2;
			line-height: 1.5;

			@extend %font-size-18;

			&__title {
				color: $lightblue3;
				font-family: $font-nimbus;
				font-weight: $font-weight-base;

				@extend %font-size-16;
			}

			&--dark {
				.single-column__content {
					color: $bluegray;
				}
			}

			&--light {
				.single-column__title,
				.single-column__content {
					color:$mediumblue;
				}
			}
		}

		&::before {
		    content: "";
		    position: absolute;
		    top: 0;
		    left: calc(((100vw - 100%)/-2));
		    width: calc(100% + (100vw - 100%));
		    height: 45%;
		    background: $mediumblue;
		    z-index: 1;

		    @include fluid((
		    	border-top-right-radius: (
		    		min: .5,
		    		max: 7.5rem,
		    	),
				border-bottom-left-radius: (
		    		min: .5,
		    		max: 7.5rem,
		    	),
		    ));
		}

		&::after {
		    content: "";
		   	position: absolute;
		    bottom: 0;
			left: calc(((100vw - 100%)/-2));
		    width: calc(100% + (100vw - 100%));
			height: 70%;
			background: $lightblue3;
			z-index: 0;
		}

		@include media-breakpoint-up(md) {
			&::before {
				right: 0;
				left: unset;
			    width: 80vw;
			    transform: translateX(-15vw);
			    height: 100%;
			    z-index: 0;
			    border-radius: 0;
			}

			&::after {
			    top: 0;
				left: calc(50% - .625rem);
				width: calc(50vw + .625rem);
				transform: translate(0);
				height: 100%;
				z-index: 1;
				border-top-right-radius: 7.5rem;
				border-bottom-left-radius: 7.5rem;
			}
		}
	}

	.wp-block-lazyblock-two-column-callout-image-and-text {
		position: relative;

		.single-text-column {
			position: relative;
			background: transparent;
			z-index: 2;

			&::before {
				content: "";
				position: absolute;
				left:-1vw;
				width: calc(50vw + 1rem);
				height: 100%;
				z-index: -1;
				border-radius: 0;
				border-top-right-radius: 7.5rem;
				background: $lightgray;
			}

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			   &::before {
			        top: 0;
			   }
			}

			&--left {
				&::before {
					right: 0;
					border-top-right-radius: 0;
					left: unset;
				}
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					&::before {
						left: auto;
					}
				}
			}

			&__superscription {
				color: $lightblue2;
				text-transform: uppercase;
				font-weight: $font-weight-base;
				font-family: $font-nimbus-extended;

				@extend %font-size-16;
			}

			&__content {
				font-family: $font-family-base;
				font-weight: $font-weight-light;

				strong,b {
					font-weight: $font-weight-base;
				}
			}

			.btn-branded {
				color: $darkgray;

				&:hover,
				&:focus {
					color: $white;
				}
			}

			.block-img-caption {
				color: $darkgray;
				font-style: italic;
				@include fluid-margin(top,6);
				@extend %font-size-13;
			}
		}

		.xs-background {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				width: 100vw;
				left: calc((100vw - 100%)/-2);
				height: 100%;
				background: $lightgray;

				@include media-breakpoint-up(md) {
					display: none;
				}
			}
		}

		.single-image {
			width: 100vw;
	    	left: calc((100vw - 100%)/-2);
	   		position: relative;

	   		@include fluid(height, 12rem, 18rem, $to: md);

			@include media-breakpoint-up(md) {
				width: 50vw;
				height: 20rem;
				min-height: 100%;
				display: flex;
				left: calc((100vw - (100%*2))/-2 + .9375rem);
				z-index: 3;

				&::before {
					content: "";
					width: 30vw;
					position: absolute;
					height: 100%;
					z-index: -1;
					background: $lightgray;
					right: 0;
				}
			}

			&__photo {
				height: 100%;

			    @include fluid(border-bottom-right-radius, .5, 7.5rem);
			}

			&--right {
				&::before {
					content: "";
					display: none;
					width: 30vw;
					position: absolute;
					height: 100%;
					z-index: -1;
					background: $lightgray;
					right: unset;
					left: -.9375rem;
				}

				@include media-breakpoint-up(md) {
					left: -.9375rem;

					&::before {
						display: block;
					}
				}

				.single-image__photo {
					border-radius: 0 !important;

					@include fluid((
						border-top-right-radius: (
							min: .5,
							max: 7.5rem,
							important: true,
						),
						border-bottom-left-radius: (
							min: .5,
							max: 7.5rem,
							important: true,
						))
					);
				}
			}
		}
	}

	.wp-block-lazyblock-fullwidth-video {
		.single-video {
			width: 100vw;
		    left: calc((100vw - 100%)/-2);

		    @include fluid(height, .5, 30rem);

			&__placeholder {
				@include fluid(border-top-right-radius, .5, 7.5rem);
			}

			&__button {
				z-index: 2;
				right: 0;

				@include fluid(bottom,2rem,4rem);
			}
		}
	}

	.wp-block-lazyblock-text-block-with-icon {
		@include fluid-margin(top,7);
	    @include media-breakpoint-up(md) {
			height:100%;
		}
		.block {
			font-weight: $font-weight-base;
	    	@include media-breakpoint-up(md) {
				position:relative;
				height:100%;
				padding-bottom:60px;
			}
			&__icon {
				background: $lightgray;
				margin: 0 auto;
				border-radius: 50%;

				@include fluid(padding, crunch-spacer(2), crunch-spacer(5));
				@include fluid((
					width: (
						min: .75,
						max: 9.5rem,
					),
					height: (
						min: .75,
						max: 9.5rem,
					),
				));
			}

			&__headline,
			&__content {
				color: $darkgray;
			}

			&__link {
		    	@include media-breakpoint-up(md) {
					position: absolute;
				    bottom: 60px;
				    left: 50%;
				    transform: translate(-50%,0);
				    width: 100%;
				    a {
				    	margin-top:0 !important;
				    }
			    }
			}
		}
	}

	.wp-block-columns.has-2-columns {
		justify-content: center;

		.wp-block-column {
			@include media-breakpoint-up(lg) {
				flex-basis: calc(35% - 1rem);
			}

			&:not(:first-child) {
				@include media-breakpoint-up(lg) {
					padding-left: 4rem;
				}
			}
		}
	}

	.wp-block-lazyblock-fullwidth-text-callout {
		position: relative;

		@extend %font-size-24;
		@include fluid-margin(top,7);

		&::before {
			content: "";
			position: absolute;
			top: 0;
			width: 100vw;
			height: 100%;
			background: $lightblue3;
			left: calc((100vw - 100%)/-2);

			@include fluid(border-top-right-radius, .5, 7.5rem);
		}

		.content {
			color: $darkgray;
		}
	}