/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Homepage Filters styles ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    .mobile-modal-toggle {
        background: $mediumblue;
        color: $lightblue;
        width: 1.75rem;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 9;

        &:hover {
            background: $lightblue;
            color: $white;
        }
    }

    .modal-dialog {
        margin: 0;
        height: 100%;
        max-width: 100vw !important;
    }

    .mobile-modal {
        &__button-close {
            width: 2rem;
            height: .75rem;
            position: relative;
            z-index: 3;
            transform: none;
            top: rem(-4px);
            top: 2rem;
            right: 2rem;

            @extend %single-transition;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                padding: 1.125rem;
                background: $mediumblue;
                transform: translate(-50%, -45%);
                border-radius: 50%;
                border-top-left-radius: 0;
            }

            span {
                height: 2px;
                background: $lightblue3;
                position: absolute;
                max-width: 1rem;
                left: 50%;

                @extend %single-transition;

                &:nth-of-type(1) {
                    top: 0;
                    transform: translate(-50%, 0.35rem) rotate(45deg);
                }

                &:nth-of-type(2) {
                    top: 50%;
                    transform: translateX(-50%) rotate(-45deg);
                }
            }
        }

        .modal-content {
            min-height: 100%;
            border: none;
            background: $darkblue;
            color: $white;
            border-radius: 0;
            overflow: scroll;
            padding-bottom: 5rem;

            .all-results-button {
                position: absolute;
                left: 0;
                bottom: 0;
                border: none;
            }
        }
    }

    .admin-bar {
        .mobile-modal {
            height: calc(100% - 2.5rem);
        }
    }

    .filters-toggle {
        background: transparent;
        border: 0;
        color: #fff;
        z-index: 1;
        display: flex;

        @extend %single-transition;

        svg {
            path {
                @extend %single-transition;
            }
        }

        &:hover {
            background: $lightblue3 !important;

            svg {
                path {
                    fill: $white !important;
                }
            }
        }

        &.open-filter-btn {
            position:fixed;
            top:0;
            left:0;
            width: 2.25rem;
            height:100%;
            color: $white;
            background:$mediumblue;
            transform:translateX(-100%);
            transition:all .35s ease;
            outline:none;
            justify-content: center;

            &:hover {
                background: $lightblue2 !important;
                color: $white;
            }

            &.open {
                transform: translateX(0);
            }
        }
    }

    .toggles {
        border-radius: 4rem;
        background: $mediumblue;

        @include media-breakpoint-up(md) {
            position: absolute;
            right: 1.625rem;
            top: -3.5rem;
            margin-top: 0;
        }

        @include media-breakpoint-up(lg) {
            background: none;
            border-radius: 0;
        }

        .toggle-view {
            background: transparent;
            border: 0;
            outline:none;

            @include fluid((
                width: (
                    min: .75,
                    max: 3rem,
                ),
                height: (
                    min: .75,
                    max: 3rem,
                ),
            ));

            @include media-breakpoint-up(lg) {
                background: transparent !important;
                width: auto !important;
                color: $white;
                height: auto !important;
                display: block;
                padding: .5rem;
                border-bottom: 2px solid $bluegray;
            }

            &::before {
                text-transform: uppercase;
                white-space: nowrap;
                font-family: $font-nimbus;
                font-weight: $font-weight-semibold;
                font-size: .75rem;

                @extend %letter-spacing-100;
            }

            &:focus {
                outline:none;
            }

            &--list {
                background: url(../img/icon-list.png) no-repeat center right;
                background-size: auto 100%;

                &::before {
                    @include media-breakpoint-up(lg) {
                        content: "List View";
                    }
                }

                &.active,
                &:hover {
                    background: url(../img/icon-list-active.png) no-repeat center right;
                    background-size: auto 100%;
                    display:inline-block;
                    color: $lightblue3;
                }

                &.active {
                    border-bottom-color: $lightblue3;
                }

                &.hidden {
                    display:none;
                }
            }

            &--network {
                background: url(../img/icon-network.png) no-repeat center center;
                background-size:100% auto;
                position:relative;
                z-index:1;

                &::before {
                    @include media-breakpoint-up(lg) {
                        content: "Network View";
                    }
                }

                &.active,
                &:hover {
                    background: url(../img/icon-network-active.png) no-repeat center center;
                    background-size:100% auto;
                    display:inline-block;
                    color: $lightblue3;
                }

                &.active {
                    border-bottom-color: $lightblue3;
                }

                &.hidden {
                    display:none;
                }
            }
        }
    }

    .left-sidebar-background {
        display: none;
        width: 100%;
        max-width: 38%;
        z-index: 1;

        @include media-breakpoint-up(lg) {
            max-width: 30%;
        }

        @include media-breakpoint-up(xl) {
            max-width: 25rem;
        }

        @media (min-width:1200px) and (max-width:1300px) {
            max-width: 20rem;                
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            right: -25vw;
            height: calc(100% + 3rem);
            top: -1.5rem;
            width: 200vw;
            z-index: -1;

            @include media-breakpoint-up(md) {
                right: 0;
                height: calc(100% + 100vh);
                min-height: 200vh;
                top: -100vh;
            }
        }

        &.show-filters {
            display: block;

            @include media-breakpoint-up(lg) {
                display: block;
            }

            &::before {
                background: $mediumblue;
            }
        }
    }

    #left-sidebar {
        -ms-overflow-style: none;  // IE 10+
        overflow: -moz-scrollbars-none !important;  // Firefox
        position:relative;
        z-index: 1;

        &::-webkit-scrollbar {
            width: 0 !important;
            display: none;
        }

        @include fluid(padding-left, 1rem, 4.5rem);

        @include media-breakpoint-up(md) {
            padding-right: 0;
            position: fixed !important;
            max-width: 38%;
            top: 0;
            left: 0;
            max-height: 100vh;
            overflow: auto;
        }

        @include media-breakpoint-up(lg) {
            max-width: 30%;
        }

        @include media-breakpoint-up(xl) {
            max-width: 25rem;
            width: 100%;
        }

        @media (min-width:1200px) and (max-width:1300px) {
            max-width: 20rem;                
        }

        .left-sidebar__logo {
            z-index: 1;
            opacity:0;

            @include fluid((
                width: (
                    min: 8rem,
                    max: 12rem,
                ),
                height: (
                    min: .5,
                    max: 4.2rem,
                ),
            ));
        }

        .active-filters {
            display: none;
            bottom: calc(100% + .75rem);
            right: 0;
            border-radius: 4rem;
            color: $lightblue3;
            white-space: nowrap;
            padding: 0 1rem;
            cursor: pointer;

            @include media-breakpoint-up(md) {
                bottom: auto;
                right: auto;
                padding: .5rem 1rem;
                opacity: 0;
                visibility: hidden;
                transform: translate(-100%, -50%);
            }

            &:hover {
                background: $lightblue3;
                color: $darkblue;
            }
        }

        &.collapsed {
            @include media-breakpoint-up(md) {
                padding-left: 2rem;
                position: absolute !important;
            }

            @include media-breakpoint-up(lg) {
                padding-left: 1rem;
            }

            form.search-form {
                input {
                    background: $darkblue !important;
                    border-color: $lightblue3 !important;
                    color: $white !important;
                }
            }

            .active-filters {
                @include media-breakpoint-up(md) {
                    opacity: 1;
                    visibility: visible;
                    transform: translate(0, -50%);
                    left: 100%;
                    background: $mediumblue;
                    margin-left: 1rem;
                    top: 50%;
                }

                &:hover {
                    background: $lightblue3;
                    color: $darkblue;
                }
            }
        }

        .search-wrapper {
            opacity: 0;
            position:relative;

            @include media-breakpoint-up(md) {
                min-height: 4.5rem;
            }

            @include media-breakpoint-up(xl) {
                min-height: 5rem;
            }

            > .row {
                display: none;

                @include media-breakpoint-up(md) {
                    display: flex;
                }
            }

            .main-network-link {
                font-family: $font-nimbus;
                color: $bluegray;

                &:hover {
                    color: $white;
                }

                svg {
                    width: .55rem;
                    height: auto;
                    transform: rotate(90deg);
                    margin-top: -2px;
                }
            }
        }

        &.show-filters {
            padding-top: 0;

            @include fluid(padding-bottom, 4rem, 6rem, $from: md);

            @include media-breakpoint-up(md) {
                z-index: 11;
                overflow: hidden;
                overflow-x: hidden;
                overflow-y: scroll;
            }

            .active-filters {
                display: block;
            }

            .search-wrapper {
                > .row {
                    display: flex;
                }
            }

            form.search-form {
                width: 100%;

                input {
                    background: $darkblue;
                    border-color: $lightblue3;
                    color: $white;
                    font-weight: $font-weight-semibold;
                    letter-spacing: 0;

                    &::-ms-clear {
                        display: none;
                    }

                    @include media-breakpoint-up(md) {
                        background: $white;
                        border-color: $white;
                        color: $gray;

                        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            color:$darkgray;
                        }

                        &::-moz-placeholder { /* Firefox 19+ */
                            color:$darkgray;
                        }

                        &:-ms-input-placeholder { /* IE 10+ */
                            color:$darkgray;
                        }

                        &:-moz-placeholder { /* Firefox 18- */
                            color:$darkgray;
                        }
                    }
                }
            }

            // .facets-group {
            //     &::before {
            //         content: "";
            //         display: block;
            //         position: absolute;
            //         right: -25vw;
            //         height: calc(100% + 3rem);
            //         top: -1.5rem;
            //         width: 200vw;
            //         background: $mediumblue;
            //         z-index: -1;

            //         @include media-breakpoint-up(md) {
            //             right: 0;
            //             height: calc(100% + 100vh);
            //             min-height: 200vh;
            //             top: -100vh;
            //         }
            //     }
            // }
        }

        .close-filters-btn {
            transform: translate(-100px,-115px);
            z-index: 11 !important;
            background: #1d2034;
            border-radius: 100%;
            border-top-left-radius: 0;
            padding: .75rem;
            color: $lightblue3;

            @include fluid(top, 0, -2rem, $from: md);

            @include media-breakpoint-up(md) {
                transform: translate(0,-115px);
            }

            &:hover,
            &:focus {
                background: $lightblue3;
            }

            svg {
                @include fluid((
                    width: (
                        min: .75,
                        max: 1rem,
                    ),
                    height: (
                        min: .75,
                        max: 1rem,
                    ),
                ));
            }
        }

        .filter-element {
            transition:all .35s ease;
            content:"";
            background:$mediumblue;
            position: fixed;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100vh;
            transform: translate(-100%,0);
            z-index:-1;
            max-width: unset;
        }

        .filters-toggle {
            position: absolute;

            @include fluid(right, 1.5rem, 2rem, $from: md);
        }

        hr {
            margin-top: 0;
            border-top: 1px solid $darkblue;
        }

        .number-of-results,
        .filters-toggle,
        .init-headline,
        .trending-content,
        .suggested-searches,
        .close-filters-btn,
        .filter-element,
        .main-network-link,
        .facets-group {
            @extend %single-transition;
        }

        .init-headline {
            opacity: 0;
            max-height:500px;
            height:auto;

            &--mobile {
                margin-top: 15rem;

                @include media-breakpoint-up(md) {
                    margin-top: 0;
                }
            }
        }

        .trending-content,
        .suggested-searches {
            max-height:500px;
            height:auto;
            overflow:hidden;
        }

        .suggested-searches {
            position: relative;

            @include media-breakpoint-up(md) {
                opacity: 0;
                z-index: -2;
            }

            &:hover,
            &:focus {
                opacity: 1 !important;
                z-index: 1 !important;
            }
        }

        .backToNetworkBtn {
            color: #fff;
        }

        .number-of-results,
        .filters-toggle,
        .facets-group,
        .main-network-link,
        .backToNetworkBtn {
            opacity:0;
            z-index:-1;
        }

        &.show-filters {
            .filter-element {
                transform: translate(0,0);
            }

            .main-network-wrapper {
                display: flex !important;
            }

            .init-headline,
            .trending-content,
            .suggested-searches {
                opacity:0;
                max-height:0;
                height: 0;
                display: none;
            }

            .trending-content,
            .suggested-searches {
                @include media-breakpoint-up(md) {
                    display: block;
                }
            }

            .number-of-results,
            .filters-toggle,
            .facets-group,
            .main-network-link,
            .backToNetworkBtn {
                opacity:1;
                z-index:0;
            }

            .number-of-results {
                font-family: $font-nimbus-extended;
                text-transform: uppercase;
                color: $bluegray;

                @extend %letter-spacing-100,
                        %font-size-11;
            }

            &.collapsed {
                .close-filters-btn,
                .filter-element,
                .facets-group {
                    transform: translate(-500px,0);
                    opacity:0;
                }

                .close-filters-btn {
                    transform: translate(-500px,-115px);
                    opacity:0;
                }
            }
        }
    }

    .sort-options {
        opacity:0;
        z-index:-1;
        transition:all .35s ease;
        top:0;
        right:0;
        color: $bluegray;
        font-weight: $font-weight-light;
        top: -.25rem;
        padding: .5rem 1.125rem;
        position: relative;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: -3rem;
            margin-top: 0;
            margin-right: 1.5rem;
        }

        &.visible {
            opacity: 1;
            z-index: 1;
        }

        .sort {
            background: transparent;
            border: none;
            outline: none;
            color: $bluegray;
            font-weight: $font-weight-light;
            cursor: pointer;

            @extend %single-transition;

            &:hover {
                color: $lightblue3;
            }
        }

        svg {
            width: .75rem;
            height: auto;
            display: block;
            margin-left: 1rem;
        }

        select {
          /* for Firefox */
          -moz-appearance: none;
          /* for Chrome */
          -webkit-appearance: none;
        }

        /* For IE10 */
        select::-ms-expand {
          display: none;
        }
    }

    .facets-group {
        .refine-by {
            font-family: $font-nimbus-extended;
            text-transform: uppercase;
            color: $bluegray;
            font-weight: $font-weight-base;

            @extend %letter-spacing-100,
                    %font-size-11;
        }

        .clear-selection {
            display:block;
            text-align: right;
            font-size:13px;
        }

        label {
            color: $bluegray;
            padding-left: 1.25rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-top: .25rem;
            font-weight: $font-weight-light;

            &:hover {
                input[type="checkbox"] {
                    background: $bluegray;
                }
            }

            &.childtag {
                display:none !important;
                margin-left:1.5rem;
                &[data-show="true"] {
                    display:flex !important;
                }
            }

            h6 {
                font-weight: $font-weight-semibold;

                @extend %font-size-14;
            }

            &:first-of-type {
                margin-top: 1rem;
            }

            &:last-of-type {
                margin-bottom: 1rem;
            }

            input[type="checkbox"] {
                margin-right: .625rem;
                -webkit-appearance: none;
                border: 1px solid $bluegray;
                width: .875rem;
                height: .875rem;
                background-position: center center;

                @extend %single-transition;
                &.checked,
                &:checked {
                    border-color: $white;
                    background: $white url(../img/icon-close.svg) no-repeat center center;
                    background-size: .5rem;
                }

                &:focus {
                    outline: none;
                }
            }
        }

        .button-clear {
            color: $lightblue3;

            &:hover {
                color: $white;
            }
        }

        .facet {
            overflow: hidden;
            background: transparent;
            border:1px solid $lightblue3;
            border-radius: 1.75rem;
            border-top-left-radius: 0;
            margin-top: .75rem;

            @extend %single-transition;

            button {
                position: relative;
                padding: 1rem 2rem .75rem 1.5rem;
                width: 100%;
                text-align: left;
                min-height: 2.5rem;
                display: block;

                &:focus {
                    outline:none;
                }

                &:hover {
                    background: $lightblue3;

                    h6 {
                        color: $mediumblue !important;
                    }

                    .active-filters-container {
                        color: $mediumblue !important;
                    }

                    svg {
                        path {
                            fill: $white !important;
                        }
                    }
                }

                &[aria-expanded="true"] {
                    svg {
                        transform: rotate(180deg);
                    }

                    .active-filters-container {
                        max-height: 0;
                        color: transparent !important;
                    }
                }

                svg {
                    top: 1.25rem;
                    display: block;
                    position: absolute;
                    width: 1rem;
                    height: .625rem;
                    right: 1.25rem;
                    transform: rotate(0);

                    @extend %single-transition;

                    path {
                        @extend %single-transition;
                    }
                }

                h6 {
                    color: $white;
                    font-family: $font-nimbus-extended;
                    text-transform: uppercase;

                    @extend %font-size-13,
                            %letter-spacing-100,
                            %single-transition;
                }

                .active-filters-container {
                    color: $bluegray;
                    margin-top: .25rem;
                    max-height: 10rem;
                    // transition: color .2s ease-in-out;
                    transition: max-height .5s ease-in-out;
                    transition-delay: .2s;

                    @extend %font-size-14;
                }
            }

            .collapse {
                border-top: 1px solid $lightblue3;
            }
        }
    }

    .admin-bar {
        .category-sidebar-wrapper {
            @include media-breakpoint-up(md) {
                height: calc(100vh - 2rem) !important;
            }
        }
    }

    .category-sidebar-wrapper-content {
        width: 100%;
        left: 0;
        top: 6.5rem;
        padding-top: 3.5rem;
        height: calc(100vh - 6.5rem);
        overflow: scroll;

        @include fluid(padding-left, 1rem, 4.5rem, $important: true);
    }

    .category-sidebar-wrapper {
        height: 100vh !important;
        background: $mediumblue;
        left: 0;
        // overflow: auto;
        // -ms-overflow-style: none;
        // overflow-x: hidden;

        ::-webkit-scrollbar {
            width: 0px;  /* Remove scrollbar space */
            background: transparent;  /* Optional: just make scrollbar invisible */
        }
    }

    #category-sidebar {
        top: 0;
        z-index: 2;
        transform: translateX(-150vw);

        @include fluid(padding-right, 1.5rem, 2rem, $from: md);
        @include fluid(padding-left, 1rem, 4.5rem);

        @include media-breakpoint-up(xl) {
            width: 100%;
            max-width: 26rem;
        }

        @include media-breakpoint-up(xxl) {
            padding-right: 4rem;
            max-width: none;
            width: 27%;
        }

        &.show {
            transform: translateX(0vw);
        }

        .category-sidebar {
            top: 0;
            padding-top: 4rem;

            @include fluid(padding-right, 1.875rem, 2rem, $from: md);

            @media (min-width: 1600px) {
                padding-right: 4rem;
            }
        }

        .category-sidebar__title {
            color: $white;
            text-transform: none;
            font-family: $font-family-base;
            font-weight: $font-weight-light;

            @include fluid(font-size, 2rem, 2.875rem); // 32px 46px

            @extend %single-transition;
        }

        .entry-content {
            font-weight: $font-weight-base;
            border-top: none;

            @extend %font-size-18;
        }

        p {
            color: $white;
        }
    }

    .category-sidebar-close {
        position: absolute;
        border: 0;
        z-index: 12;
        background: #1d2034;
        border-radius: 100%;
        border-top-left-radius: 0;
        padding: .75rem;
        color: $lightblue3;
        transform: translateX(-100vw);
        right: 0;
        top: -3rem;

        @include fluid(top, -2.5rem, -3.5rem, $from: md);
        @extend %single-transition;

        @include media-breakpoint-up(md) {
            right: 62%;
        }

        @include media-breakpoint-up(lg) {
            right: 70%;
        }
        @include media-breakpoint-up(xl) {
            right: auto;
            left: 18rem;
        }

        @include media-breakpoint-up(xxl) {
            right: 77.7%;
            left: auto;
            top: -7rem;
        }

        &.show {
            transform: translateX(0);
        }

        svg {
            width: 1rem;
            height: 1rem;

            path {
                @extend %single-transition;
            }
        }

        &:hover {
            background: $lightblue3 !important;

            svg {
                path {
                    fill: $white !important;
                }
            }
        }

        &:focus {
            outline: none;
        }
    }

    .all-results-button {
        right: 0;
        bottom: 0;
        z-index: 2;
        background: $darkerblue;
        color: $lightblue3;
        font-family: $font-nimbus;

        @include fluid(width, calc(100% + 0rem), calc(100% + 3.5rem));

        &:hover,
        &:focus {
            background: $lightblue3;
            color: $white;
        }
    }


    @keyframes dot-keyframes {
      0% {
        opacity: .4;
        transform: scale(1, 1);
      }

      50% {
        opacity: 1;
        transform: scale(1.2, 1.2);
      }

      100% {
        opacity: .4;
        transform: scale(1, 1);
      }
    }

    .loading-dots {
      text-align: center;
      width: 100%;

      &--dot {
        animation: dot-keyframes 1.5s infinite ease-in-out;
        background-color: #51c0dc;
        border-radius: 10px;
        display: inline-block;
        height: 10px;
        width: 10px;

        &:nth-child(2) {
          animation-delay: .5s;
        }

        &:nth-child(3) {
          animation-delay: 1s;
        }
      }
    }


@media (min-width:1200px) and (max-width:1300px) {
    #left-sidebar.show-filters .number-of-results,
    #left-sidebar .search-wrapper .main-network-link {
        font-size:10px !important;
    }
    .results-wrapper .list-view {
        padding-left: .5rem !important;
    }
    .results-wrapper #st-results-container .st-result {
        -ms-flex-preferred-size: calc(33.33% - 1rem) !important;
        flex-basis: calc(33.33% - 1rem) !important;
        margin: .5rem !important;
    }
    .results-wrapper #st-results-container .st-result .post-image-wrapper.headshot .headshot-wrapper:hover {
        transform: scale(1.05) translate(-5px,0) !important;
    }
}
