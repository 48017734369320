/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Home - Template ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	body.home {
		background: $darkblue;
		color: $white;

		&.overflow-hidden {
		    padding-right: 15px;
		}

		&.mobile-bar {
			padding-left: 1.75rem;

			@include media-breakpoint-up(md) {
				padding-left: 0;
			}
		}

		#main-navigation {
			opacity: 0;

			.hero-section__logo {
				@include media-breakpoint-up(md) {
					display: none !important;
				}
			}
		}

		.body-content {
			.interior-search {
				display: none;
			}
		}

		.main-footer {
			color: $white;
			opacity: 0;

			.footer-navigation {
				.menu-item {
					a {
						color: $white;

						&:hover,
						&:focus {
							color: $lightblue3 !important;
						}
					}
				}
			}
		}

		.category-info-button {
			right: 1.5rem;
			cursor: pointer;
			height: 2.5rem;
			opacity: 0;

			&__icon {
				position: relative;
				width: 2.5rem;
			    padding: 0.75rem;
				height: 100%;
				border-radius: 50%;
				border-top-left-radius: 0;
				background: $mediumblue;
				cursor: pointer;

				@extend %single-transition;

				&:hover {
					background: $lightblue2;
				}
			}

			&__text {
				position: absolute;
				top: 0;
				right: 0;
				color: transparent;
				border-bottom-left-radius: 1.5rem;
				border-bottom-right-radius: 1.5rem;
				border-top-right-radius: 1.5rem;
				font-size: 0;
				transform-origin: right;
				transform: scaleX(0);
				height: 100%;

				transition: transform 0.25s ease-in-out;
			}

			&.active {
				.category-info-button__icon {
					position: absolute;
					right:0;
				}

				.category-info-button__text {
					width: auto;
					display: flex;
					position: relative;
					transform: scaleX(1);
					color: $darkgray;
				    align-items: center;

					@extend %font-size-12;
				}
			}

			&.out {
				&__text {
					font-size: 0;
					transform-origin: right;
					transform: scaleX(0);
					transition: transform 0.25s ease-in-out;
				}
			}
		}
	}

	//Home page preloader
    body.home {
    	.introHeadlineWrapper {
        	position:fixed;
        	top:0;
        	left:0;
        	width:100%;
        	height:100%;
        	text-align:center;
        	display:table;
        	z-index:1;
        	transition:all 1s ease;
        	h1 {
	        	&.introHeadline {
			        transition:all .35s ease;
			        display:table-cell;
			        height:100%;
			        width:100%;
			        vertical-align:middle;
			        font-size:50px;
			    }
        	}
    	}
    }