/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Network Elements styles ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	canvas {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		z-index: -2;
		@media (max-width:768px) {
		position:absolute;
		}
	}

	#heroElement {
		filter: url('#mitosis-effect');
	}

	#skip-button {
		position: fixed;
		bottom: 100px;
		right: 100px;
		color: white;
		text-transform: uppercase;
		font-family: nimbus-sans, sans-serif;
		font-size: 14px;
		letter-spacing: 2px;
		cursor: pointer;
		z-index:10;

		&::after {
			content: "";
			width: 20px;
			height: 20px;
			background: url("../img/dropdown-arrow-network.png") no-repeat 93% center;
			background-size: 20px auto;
			display: inline-block;
			transform: rotate(-90deg);
			vertical-align: text-bottom;
		}
	}

	@media (min-width:1070px) and (max-width:1200px) {
		#heroElement,
		#textElement {
			transform:translateX(100px);
		}
	}