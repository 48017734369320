/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Placeholders ~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    /* ~~~~~~~~~~ Single transition ~~~~~~~~~~ */

        %single-transition {
            transition: all 0.2s ease-in-out;
        }


    /* ~~~~~~~~~~ Fill the whole space ~~~~~~~~~~ */

        %fill-the-whole-space{
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }


    /* ~~~~~~~~~~ Font Size Classes ~~~~~~~~~~ */

        %font-size-11 {
            font-size: .6875rem;
        }

        %font-size-12 {
            font-size: .75rem;
        }

        %font-size-13 {
            font-size: .8125rem;
        }

        %font-size-14 {
            font-size: .875rem;
        }

        %font-size-15 {
            font-size: .9375rem;
        }

        %font-size-16 {
            font-size: 1rem;
        }

        %font-size-17 {
            font-size: 1.0625rem;
        }

        %font-size-18 {
            @include fluid(font-size, 1.0625rem, 1.125rem); // 17px 18px
        }

        %font-size-20 {
            @include fluid(font-size, 1.125rem, 1.25rem); // 18px 20px
        }

        %font-size-22 {
            @include fluid(font-size, 1.1875rem, 1.375rem); // 19px 22px
        }

        %font-size-24 {
            @include fluid(font-size, 1.25rem, 1.5rem); // 20px 24px
        }

        %font-size-28 {
            @include fluid(font-size, 1.375rem, 1.75rem); // 22px 28px
        }

        %font-size-35 {
            @include fluid(font-size, 1.625rem, 2.1875rem); // 26px 35px
        }

        %font-size-50 {
            @include fluid(font-size, 2.625rem, 3.125rem); // 33px 50px
        }


    /* ~~~~~~~~~~ Letter Spacing Classes ~~~~~~~~~~ */

        %letter-spacing-25 {
            @include fluid( letter-spacing, 25);
        }
        %letter-spacing-50 {
            @include fluid( letter-spacing, 50);
        }

        %letter-spacing-100 {
            @include fluid( letter-spacing, 100);
        }

        %font-nimbus-extended {
            font-family: $font-nimbus-extended;
        }


    /* ~~~~~~~~~~ Animated Border Bottom ~~~~~~~~~~ */

        %animated-border-bottom {
            text-decoration: none;
            background-image: linear-gradient( to top, currentColor #{rem(1px)}, transparent #{rem(1px)});
            background-size: 0% 100%;
            background-repeat: no-repeat;
            transition: background-size ease-in-out .4s;
            padding-bottom: .125rem;

            &:hover,
            &:focus {
                background-size: 100% 100%;
            }

            &--reverse {
                background-size: 100% 100%;
                background-position: right;

                &:hover,
                &:focus {
                    background-size: 0% 100%;
                }
            }
        }

