/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Footer Styles ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	.main-footer {
		bottom: 0;
		color: $darkblue;

		@include media-breakpoint-up(md) {
			position: absolute;
		}

		.footer-navigation {
			.menu-item {
				position: relative;
				margin-bottom: .5rem;
				padding-bottom: .5rem;

				@include media-breakpoint-up(md) {
					margin-bottom: 0;
					padding-bottom: 0;
				}

				&::before {
					content: "|";
					display: none;

					@include fluid(padding-x, .5rem, .75rem);

					@include media-breakpoint-up(md) {
						display: inline;
					}
				}

				&:first-child {
					&::before {
						display: none;

						@include media-breakpoint-up(lg) {
							display: inline;
						}
					}
				}

				&::after {
					content: "";
					display: block;
					position: absolute;
					background: $darkblue;
					width: 1rem;
					height: 1px;
					bottom: 0;
					left: calc(50% - .5rem);

					@include media-breakpoint-up(md) {
						display: none;
					}
				}

				a {
					color: $darkblue;

					&:hover,
					&:focus {
						color: $lightblue2;
					}
				}
			}
		}
	}