/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Mixins ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    /* ~~~~~~~~~~ Helper Functions ~~~~~~~~~~ */

        /* ~~~~~ Strip Unit ~~~~~ */

            @function strip-unit($value) {
                @return $value / ($value * 0 + 1);
            }


        /* ~~~~~ Convert to REM ~~~~~ */

            @function rem($value, $base-value: 16px) {
                $value: strip-unit($value) / strip-unit($base-value) * 1rem;
                @return $value;
            }


        /* ~~~~~ Get Crunch Spacer ~~~~~ */

            @function crunch-spacer($index) {
                @if (map-has-key($crunch-spacers, $index)) {
                    @return map-get($crunch-spacers, $index);
                }
            }


    /* ~~~~~~~~~~~~ Fluid  ~~~~~~~~~~~~ */

        @import "../mixins/fluid.scss";


    /* ~~~~~~~~~ Fluid margin/padding helpers ~~~~~~~~~ */

        @import "../mixins/spacings.scss";


    /* ~~~~~~~~~~ Sizing Class Generator ~~~~~~~~~~ */

        @import "../mixins/classes.scss";


    /* ~~~~~~~~~~ Respond to specific brekpoint ~~~~~~~~~~ */

    @mixin respond-to($breakpoint) {
        // If the key exists in the map
        @if map-has-key($grid-breakpoints, $breakpoint) {
            // Prints a media query based on the value
            @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
                @content;
            }
        }
    }
