/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Variables ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    /* ~~~~~~~~~~ WP Admin Bar heights ~~~~~~~~~~ */

        $mobile-wp-admin-bar-height: 2.875rem;
        $desktop-wp-admin-bar-height: 2rem;


    /* ~~~~~~~~~~ Settings ~~~~~~~~~~ */

        $design-source: "adobe";

        $crunch-breakpoints: $grid-breakpoints !default;
        $crunch-spacers: (
            1: .25rem,
            2: .5rem,
            3: 1rem,
            4: 1.5rem,
            5: 2rem,
            6: 3rem,
            7: 5rem,
            8: 6.25rem,
            9: 8rem
        );

        $fluid-defaults: (
            min: null,
            max: null,
            from: xs,
            to: xxl,
            important: false,
        );


    /* ~~~~~~~~~~ Fonts ~~~~~~~~~~ */

        $font-weight-thin: 100;
        $font-weight-extralight: 200;
        $font-weight-light: 300;
        $font-weight-medium: 500;
        $font-weight-semibold: 600;


    /* ~~~~~~~~~~ Plugins ~~~~~~~~~~ */

        /* ~~~~~ OWL Carousel ~~~~~ */

            $owl-image-path: "../../../../images/owl.carousel/";
