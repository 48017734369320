/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Pagination Partial Styles ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	#st-pagination-container {
		opacity: 0;

		@include fluid-margin( top, 6);

		.prev-page {
			svg {
				transform: rotate(90deg);
			}
		}

		.next-page {
			border-left: 1px solid $bluegray;

			svg {
				transform: rotate(-90deg);
			}
		}

		.prev-page,
		.next-page {
			font-family: $font-nimbus-extended;
			font-weight: $font-weight-light;
			color: $bluegray;

			&:hover {
				color: $white;
			}

			svg {
				width: .55rem;
				height: auto;
				margin-top: -2px;
			}

			&.inactive {
				display: none !important;
			}
		}

		.inactive ~ button {
			padding-left: 0 !important;
			border-left: none !important;
		}
	}