/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Default Settings Styles ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	.container-fluid {
		@include fluid(padding-x, 1rem, 4.5rem, $important: true);
	}

	.col-xxl-3 {
		@include media-breakpoint-up(xxl) {
			flex: 0 0 25%;
			max-width: 25%;
		}
	}

	.col-xxl-9 {
		@include media-breakpoint-up(xxl) {
			flex: 0 0 75%;
    		max-width: 75%;
		}
	}