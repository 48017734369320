/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Search Elements styles ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	form {
		&.search-form {
			position:relative;
			// width: 260px;
			width: 100%;
			z-index:2;

			input[type="text"],
			input[type="search"] {
				height: 3rem;
				line-height: 3rem;
				padding-left: 1.25rem;
				padding-right: 3rem;
				font-size: .75rem;
				text-transform: uppercase;
				letter-spacing: .1em;
				font-family: $font-nimbus-extended;
				background: $darkblue;
				border:1px solid $midblue;
				border-radius:30px;
				border-top-left-radius: 0;
				width:100%;
				color:$bluegray;
				position: relative;
				z-index: 2;

				&:focus {
					background:#fff;
					color: $darkgray;
					outline:none;
					border-color: #fff;

					&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
						color:$darkgray;
					}

					&::-moz-placeholder { /* Firefox 19+ */
						color:$darkgray;
					}

					&:-ms-input-placeholder { /* IE 10+ */
						color:$darkgray;
					}

					&:-moz-placeholder { /* Firefox 18- */
						color:$darkgray;
					}
				}
			}

			> button,
			> i {
				position:absolute;
				top:0;
				right: 0;
				height: 100%;
				width: 4rem;
				color:$lightblue2;
				z-index:3;
				line-height: 1;
				margin-top: -2px;
			}

			::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: $bluegray;
			}

			::-moz-placeholder { /* Firefox 19+ */
				color: $bluegray;
			}

			:-ms-input-placeholder { /* IE 10+ */
				color: $bluegray;
			}

			:-moz-placeholder { /* Firefox 18- */
				color: $bluegray;
			}
		}
	}

	body {
		.swiftype-widget {
			.autocomplete {
				z-index:0;
				background: $mediumblue;
				border-radius: 20px;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				margin-top: -0;
				box-shadow: none;
				padding: 0 0 5px 0;
				opacity: 0;

				&::before,
				&::after {
					content: "";
					display: block;
					position: absolute;
					left: 0;
					top: -22px;
					height: 21px;
					width: 21px;
					background: white;
					transition: none;
				}

				@extend %single-transition;

				&::after {
					left: auto;
					right: -1px;
				}

				&:hover {
					opacity: 1;
					z-index: 1;
				}

				&.show {
					opacity: 1;
					z-index: 1;
				}

				ul, li {
					list-style-type:none;
					padding: 0;
					background: transparent;
					border-bottom: 0 !important;
					position: relative;

					&::before {
						content: "";
						display: block;
						width: 100%;
						height: 60px;
						position: absolute;
						left: 0;
						top: -60px;
					}

					a,
					button {
						-webkit-appearance:none;
						background:transparent;
						border:0;
						padding: .75rem 1rem;
						font-weight: 400 !important;
						color: $lightblue3 !important;
						display: block;
						width: 100%;
						text-align: left;
						margin-top: 0 !important;

						@extend %font-size-16,
								%letter-spacing-100;

						&:hover,
						&:focus {
							color: $white !important;
						}
					}

					.st-search-result-link {
						color: #fff;
						font-weight: 200;
						margin-bottom: 5px;
					}
				}

				ul {
					padding: 1rem 0;
				}

				li,.active {
					box-shadow: none !important;
					background-image: none !important;
					border: none !important;
					border-top: none !important;
					background: transparent !important;

					&:hover,
					&:focus {
						background: transparent !important;
						border-top: none !important;
					}

					&::before,
					&::after {
						display: none !important;
					}
				}
			}
		}
	}

	.suggestions {
		position:absolute;
		top:100%;
		left:0;
		width:100%;
		z-index:1 !important;
		background: $mediumblue;
		border-radius: 20px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		margin-top: -20px;
		display: block;
		opacity: 0;
		transform: translateY(-2rem);
		visibility: hidden;

		&.visible {
			opacity: 1;
			transform: translateY(0);
			visibility: visible;
		}

		ul, li {
			list-style-type:none;

			a,
			button {
				-webkit-appearance:none;
				background:transparent;
				border:0;
				padding:0;
				font-weight: 200;
				color: $lightblue3 !important;
				display: inline-block;
				text-align: left;
				border-bottom: 1px solid transparent;

				&:hover,
				&:focus {
					border-bottom-color: $lightblue3;
				}
			}

			.st-search-result-link {
				color: #fff;
				font-weight: 200;
				margin-bottom: 5px;
			}
		}

		li {
			&:first-child {
				color: $white;
			}
		}
	}

	.trending-content,
	.suggested-searches {
		padding: .75rem 0 0 0;

		@include media-breakpoint-up(sm) {
			padding: 1rem 0 0 1rem;
		}

		@include media-breakpoint-up(md) {
			padding: 1.5rem 0 0 1rem;
		}

		button {
			-webkit-appearance:none;
			background:transparent;
			border:0;
			padding:0;
			color: $lightblue3;
			text-align: left;
			display:block;

			&:hover,
			&:focus {
				color: $white;
			}
		}
	}