/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Popup Modals styles ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

#announcementModal { 
	.modal-content {
	    max-width: 760px;
    	margin: 10vh auto;
    	min-height:600px;
    	max-height:80vh;
    	min-height:500px;
    	padding: 50px;
	    border-radius: 40px;
	    .modal-header {
	    	padding:0;
    	    padding-bottom: 30px;
		    border-bottom: 1px solid #eee;
    		margin-bottom: 30px;
	    }
	    .modal-body {
	    	padding:0;
    	    font-size: 15px;
    	    color:#313131;
	    	p {
    		    margin-bottom: 1.35em;
	    	}
	    }
	    .modal-title {
    	    color: #1e7f98;
    		font-weight: 300;
		    line-height: 1.2;
	        font-size: 1.75rem;
    		max-width: 550px;
	    }
	}
	.simplebar-track.simplebar-vertical {
    	top: 50px;
    	bottom:50px;
    }
    .simplebar-scrollbar:before {
    	background:#51c0dc;
    	opacity:1;
    }
}
.modal-backdrop {
	background-color: #090e2e;
	&.show {
    	opacity: .8;		
	}
}