/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Homepage Modals styles ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	.modal-mask {
		position: fixed;
		z-index: 99999 !important;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all .35s ease;
		max-width: 100vw;
		background: $white;
		transform:translateX(-100%);

		&.active-modal {
			transform:translateX(0);
		}

		@include media-breakpoint-up(md) {
			max-width: 75vw;
		}

		/*&::before {
			content: "";
			position: absolute;
			top: 50%;
			width: 100vw;
			height: 100%;
			background: $darkblue;
			opacity: .8;
			transform: translateY(-50%);
		}*/

		.modal-default-button {
			background: #f6f6f6;
            border-radius: 100%;
            border-top-left-radius: 0;
            padding: .875rem;

            @include fluid(top, 0, -2rem, $from: md);

            svg {
                @include fluid((
                    width: (
                        min: .75,
                        max: 1rem,
                    ),
                    height: (
                        min: .75,
                        max: 1rem,
                    ),
                ));

                path {
                	@extend %single-transition;
                }
            }

            &:hover,
            &:focus {
            	background: $lightblue3;

            	svg {
            		path {
            			fill: $white !important;
            		}
            	}
            }
		}

		.modal-wrapper {
			max-height: 100vh;
			min-height: 100vh;
			background: $white;

			.simplebar-vertical {
				right: .75rem;
				margin: .75rem 0;

				.simplebar-scrollbar {
					&::before {
						background: $lightblue3;
						opacity: 1;
						border: 1px solid $white;
					}
				}
			}

			.modal-header {
				border: 0;

				&__logo {
					@include fluid((
						width: (
							min: 8rem,
							max: 12rem,
						),
						height: (
							min: .5,
							max: 4.2rem,
						),
					));
				}
			}

			.modal-container {
				min-height: 35px;

				@include media-breakpoint-down(md) {
					padding-bottom: 5rem !important;
				}
			}
		}
	}

	.modal-enter {
		transform:translateX(-100%) !important;
	}

	.modal-leave-active {
		transform:translateX(-100%) !important;

		&::before {
			opacity: 0;
		}
	}

	.modal-background {
		position: fixed;
		top: 0;
		left:0;
		width: 100vw;
		height: 100vh;
		background: $darkblue;
		z-index:-1;
		opacity:0;
	}
	body.overflow-hidden {
		.modal-background {
			z-index:99998;
			opacity: .8;
		}
	}

	body.isIE {
		.modal-wrapper {
			overflow:auto;
		}
	}

	#left-sidebar {
		@media (min-width:992px) {
			transform:translateY(-100px);
			position: relative !important;
			top: auto;
			left: auto;
			max-height:100%;
			padding-left:0;
			padding-right:2.4rem;
		}
	}
	.results-grid {
		@media (min-width:992px) {
			left:0;
		}

	}