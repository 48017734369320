.wp-block-ninja-forms-form form {
	.nf-form-content {
	    max-width: 800px;
	    margin: 0;
	}
	label {
	    font-family: $font-family-base;
        color: #313131;
        font-weight:400;
	}
	input[type="text"],
	input[type="tel"],
	input[type="email"],
	input[type="url"],
	select,
	.nf-form-content .list-select-wrap>div select,
	textarea {
		height: 3rem;
		line-height: 3rem;
		padding-left: 1.25rem;
		font-size: .75rem;
		text-transform: uppercase;
		letter-spacing: .1em;
		font-family: $font-nimbus-extended;
		border:1px solid $midblue;
		color: $darkgray;
		border-radius:30px;
		border-top-left-radius: 0;
		width:100%;
		position: relative;
		z-index: 2;
    	-webkit-appearance: none;
		&:focus {
			outline:none;
			border-color: $darkblue;

			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color:$bluegray;
			}

			&::-moz-placeholder { /* Firefox 19+ */
				color:$bluegray;
			}

			&:-ms-input-placeholder { /* IE 10+ */
				color:$bluegray;
			}

			&:-moz-placeholder { /* Firefox 18- */
				color:$bluegray;
			}
		}
	}
	input[type="checkbox"] {
		border:1px solid $midblue;
	    -webkit-appearance: none;
	    width: 15px;
	    height: 15px;
	}
	select,
	.nf-form-content .list-select-wrap>div select {
		padding-top:0;
		padding-bottom:0;
	}

	.checkbox-wrap .nf-field-element label:after, 
	.checkbox-wrap .nf-field-label label:after, 
	.listcheckbox-wrap .nf-field-element label:after, 
	.listcheckbox-wrap .nf-field-label label:after {
        border-color: $midblue;
	}

	.checkbox-wrap .nf-field-element label.nf-checked-label:before, 
	.checkbox-wrap .nf-field-label label.nf-checked-label:before, 
	.listcheckbox-wrap .nf-field-element label.nf-checked-label:before, 
	.listcheckbox-wrap .nf-field-label label.nf-checked-label:before {
		color: $midblue;
	}

	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: $bluegray;
	}

	::-moz-placeholder { /* Firefox 19+ */
		color: $bluegray;
	}

	:-ms-input-placeholder { /* IE 10+ */
		color: $bluegray;
	}

	:-moz-placeholder { /* Firefox 18- */
		color: $bluegray;
	}
	textarea {
		height:6rem;
	}
	.nf-error.field-wrap .nf-field-element:after {
   		background: transparent;
    }

    input[type="submit"],
    .submit-wrap input[type="button"] {
    	border: 1px solid $midblue;
		border-radius: 3rem;
		border-top-left-radius: 0;
		font-weight: $font-weight-base;
		font-family: $font-nimbus-extended;
		position: relative;
        padding-top: .75rem;
        padding-bottom: .75rem;
        padding-right: crunch-spacer(6);
        min-width: 9.375rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        cursor: pointer;
        background:#fff;
        color:$lightblue2;
        position:relative;
        background: #fff url(../img/icon__arrow-right-blue.png) no-repeat 75% center;
        background-size: 7px auto;
        @include fluid-margin(top,5);
        @include fluid(padding-left, crunch-spacer(4), crunch-spacer(5));
        @extend %font-size-12,
        		%letter-spacing-100,
        		%single-transition;

       	&:hover,
       	&:focus {
			background: $midblue;
			border-color: $midblue;
			color:#fff;
			box-shadow: none;
			background: $midblue url(../img/icon__arrow-right-white.png) no-repeat 75% center;
		    background-size: 7px auto;
       	}
    }
    .nf-form-content .list-select-wrap .nf-field-element>div {
	    background: transparent;
	    border: 1px solid transparent;
    }
}

#app .list-select-wrap select:active+div, 
#app .list-select-wrap select:focus+div {
    background: transparent!important;
    border-color: transparent!important;
}

.modal-container {
	.checkbox-wrap {
		position:relative;
		padding-left:30px;
		.nf-field-element {
			position:absolute;
			top:0;
			left:0;
		}
		.nf-checked {
			position:relative;
			&:before {
				position:absolute;
				top:50%;
				left:50%;
				color: #0485b5;
				content: "\f00c";
			    font-family: FontAwesome;
			    font-size: 20px;
				transform:translate(-50%,-50%);
			}
		}
	}
}

label[aria-hidden="true"] > input {
	display: none !important;
}