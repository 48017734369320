/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Deafult Template Styles ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	body {
		position: relative;
		min-height: 100vh;
		min-height: calc(var(--vh, 1vh) * 100);

		&.admin-bar {
			min-height: calc(100vh - 2.9rem);

			@include media-breakpoint-up(md) {
			    min-height: calc(100vh - 2rem);
			}

			#app {
				@include fluid(min-height, calc(100vh - 4.9rem), calc(100vh - 5rem));
			}
		}

		.main-content {
			min-height: 100vh;
			min-height: calc(var(--vh, 1vh) * 100);
		}

		&.admin-bar {
			.main-content {
				min-height: calc(100vh - 2.9rem);

				@include media-breakpoint-up(md) {
				    min-height: calc(100vh - 2rem);
				}
			}
		}

		#app {
			position: relative;

			@include fluid(min-height, calc(100vh - 2rem), calc(100vh - 3rem));
			@include fluid(min-height, calc((var(--vh, 1vh) * 100) - 2rem), calc((var(--vh, 1vh) * 100) - 3rem));

			@media (max-width:768px) {
				min-height: calc(var(--vh, 1vh) * 100);
			}
		}
	}

	.scroll-to-top-link {
		opacity: 0;
		bottom: 1.25rem;
		right: 0;
		z-index: 2;

		@include fluid(padding-right, 3rem, 4.5rem);

		svg {
			transform: rotate(-180deg);
			width: 1.125rem;
			height: 1.12rem;
		}

		&__label {
			color: $bluegray;

			@extend %single-transition;
		}

		&:hover {
			.scroll-to-top-link__label {
				color: $lightblue2;
			}
		}
	}

	.body-content {
		color: $darkbluegray;
		overflow-x: hidden !important;
		position: relative;

		.hero-img {
			border-top-right-radius: 50px;
			margin-top: 15px;
		}

		.meta {
			color: $darkbluegray;
			font-weight: $font-weight-base;
		}

		&__title {
			color: $lightblue2;
			font-weight: $font-weight-light;
		}

		&__image-wrapper {
			width: 100%;
			height: 50vw;
			background: $lightgray;
			border-radius: 50%;
			margin-left: auto;
			margin-right: auto;

			@include fluid((
				min-width: (
					min:.5 ,
					max: 15rem,
				),
				width: (
					min: .5,
					max: 15rem,
				),
				min-height: (
					min:.5 ,
					max: 15rem,
				),
				height: (
					min: .5,
					max: 15rem,
				),
			));
			&.headshot,
			.headshot {
				.headshot-wrapper {
					background-repeat:no-repeat;
				    background-size: cover;
				    display: inline-block;
				    border-radius: 50%;
				    background-position: right 0;
				    height:100%;
				    width:100%;
				}
			    img {
			    	opacity:0;
			    	display:none;
			    }
			}

			img {
				border-radius: 50%;
				border-top-right-radius: 50%;

				@include media-breakpoint-up(md) {
					border-radius: 50%;
				}
			}

			&--full-width {
				width: 100%;

				@include fluid(height, 12rem, 18rem);

				img {
					border-radius: 0;

					@include fluid(border-top-right-radius, .75, 10rem);
				}
			}
		}

		.meta-data {
			// @include fluid-padding( bottom, 6);

			.single-meta{
				&__key {
					font-family: $font-nimbus;
					color: $darkgray;
				}

				&__value {
					color: $darkgray;
					.value {
						&--colored {
							color: $lightblue2;
						}
					}

				}

				&__divider {
					color: $lightblue2;
				}
			}
		}

		.entry-content {
			border-top: 1px solid #eee;
		}

		.wp-block-lazyblock-two-column-callout {
			&::after {
				@include media-breakpoint-up(md) {
					width: calc((75vw / 2) + .625rem);
				}
			}
		}

		.wp-block-lazyblock-two-column-callout-image-and-text {
			.single-image {
				@include media-breakpoint-up(md) {
					width: calc((75vw / 2));
					left: calc((75vw - 200%)/-2 + .9375rem);
				}

				&--right {
					@include media-breakpoint-up(md) {
						left: -.9375rem;
					}
				}
			}
		}

		.wp-block-lazyblock-fullwidth-video {
			.single-video {
				@include media-breakpoint-up(md) {
					width: 75vw;
					left: calc((75vw - 100%)/-2);
				}
			}
		}
	}