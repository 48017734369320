//* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Typography ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    /* ~~~~~~~~~~ HTML ~~~~~~~~~~ */

        :root {
            font-size: 100%;
        }

    /* ~~~~~~~~~~ Body ~~~~~~~~~~ */

        body {
            line-height: 1.75;
            font-family: $font-family-base;
            color: #313131;

            @extend %font-size-18;
        }


    /* ~~~~~~~~~~ Headlines ~~~~~~~~~~ */

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .h1
        .h2,
        .h3,
        .h4,
        .h5,
        .h6 {
            margin-bottom: 0;
            line-height: 1.3;
        }

        h1,
        .h1,
        h2,
        .h2 {
            font-weight: $font-weight-light;
        }

        h1,
        .h1 {
            @include fluid(font-size, 2rem, 2.5rem); // 32px 46px
        }

        h2,
        .h2 {
            color: $midblue;

            @include fluid(font-size, 1.75rem, 2.5rem); // 28px 40px
        }

        h3,
        .h3 {
            @include fluid(font-size, 1.375rem, 1.75rem); // 22px 28px
        }

        h4,
        .h4 {
            font-family: $font-nimbus;
            text-transform: uppercase;

            @include fluid(font-size, 1.25rem, 1.5rem); // 20px 24px
        }

        h5,
        .h5 {
            @include fluid(font-size, 1.125rem, 1.25rem); // 18px 20px
        }

        h6,
        .h6 {
            @include fluid(font-size, 1.0625rem, 1.125rem); // 17px 18px
        }


    /* ~~~~~~~~~~ Text elements ~~~~~~~~~~ */

        b,
        strong {
            font-weight: $font-weight-bold;
        }

        em {
            font-style: italic;
        }

        a {
            color: $lightblue;

            &:hover,
            &:focus {
                color: $lighterblue;
            }
        }
