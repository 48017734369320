/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Header ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	.deerfield-logo {
		img {
			max-width:165px !important;
		}
	}

	ul.menu {
		font-size:13px;
		list-style-type:none;
		margin:0;
		padding:0;
		text-align: right;

		li {
			list-style-type:none;
			padding:0;
			margin-left:30px;
			display: inline-block;

			a {
				color: $bluegray;
			}
		}
	}

	body.home {
		.menu {
			a {
				color: $lightblue;
			}
		}
	}

	.hero-section {
		&__logo {
			z-index: 1;

			@include fluid((
				width: (
					min: 8rem,
					max: 12rem,
				),
				height: (
					min: .5,
					max: 4.2rem,
				),
			));
		}

		@include media-breakpoint-down(md) {
			z-index: 10;
		}

		#main-navigation {
			position: relative;
			z-index: 1;
		}

		.primary-navigation {
			.menu-item {
				display: inline-block;

				@include fluid(padding-x, .5, .125rem, lg, xl);

				@include media-breakpoint-up(xxl) {
					padding: 0 .25rem;
				}

				&:first-of-type {
					padding-left: 0;
				}

				a {
					font-family: $font-nimbus-extended;
					color: $white !important;
					padding: .5rem 1rem;
					border: 1px solid transparent;
					border-radius: 1.5rem;
					border-top-left-radius: 0;
					text-decoration: none;

					@extend %single-transition,
							%font-size-14,
							%letter-spacing-100;

					&:hover {
						color: $lightblue3 !important;
					}

					&:focus {
						color: $white !important;
						border-color: $lightblue3;
					}
				}

				&.current-menu-item,
				&.current_page_item {
					a {
						border-color: $lightblue3;
					}
				}
			}
		}

		.mobile-navigation {
			&__button {
				width: 2rem;
				height: 1.25rem;
				position: relative;
				z-index: 11;
				transform: none;
				top: rem(-4px);
				font-family: $font-nimbus-extended;
				cursor: pointer;

				@extend %single-transition;

				&::before {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					width: 100%;
					height: 100%;
					padding: 1.25rem;
					background: $mediumblue;
					transform: translate(-50%, -50%);
					border-radius: 50%;
					border-top-left-radius: 0;

					@extend %single-transition;
				}

				span {
					height: 2px;
					background: $lightblue3;
					position: relative;
					margin: 2px 0;
					max-width: 1rem;

					@extend %single-transition;
				}

				&:hover {
					&::before {
						background: $lightblue3;
					}

					span {
						background: $mediumblue;
					}
				}
			}

			&__menu-wrapper {
				position: fixed;
				width: 100vw;
				min-height: 100vh;
				height: 100%;
				overflow: scroll;
				left: 50%;
				top: 0;
				z-index: 2;
				transform: translate(-50%);
				background: $lightblue2;
				transform-origin: top;
				transform: translateX(-50%) scaleY(0);

				@extend %single-transition;

				.mobile-navigation__buttons-wrapper {

					form {
						&.search-form {
							&:focus-within {
							  	> button {
							  		color:$darkgray !important;
							  	}
							}

							input[type="search"] {
								background: transparent;
								border:1px solid $white;
								color:$bluegray;

								&:focus {
									background:#fff;
									color: $darkgray;
									outline:none;
									border-color: #fff;

									&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
										color:$darkgray;
									}

									&::-moz-placeholder { /* Firefox 19+ */
										color:$darkgray;
									}

									&:-ms-input-placeholder { /* IE 10+ */
										color:$darkgray;
									}

									&:-moz-placeholder { /* Firefox 18- */
										color:$darkgray;
									}
								}
							}

							> button,
							> i {
								position:absolute;
								top:0;
								right: 0;
								height: 100%;
								width: 4rem;
								color:$white;
								z-index:3;
								line-height: 1;
								margin-top: -2px;
							}

							::-webkit-input-placeholder { /* Chrome/Opera/Safari */
								color: $white;
							}

							::-moz-placeholder { /* Firefox 19+ */
								color: $white;
							}

							:-ms-input-placeholder { /* IE 10+ */
								color: $white;
							}

							:-moz-placeholder { /* Firefox 18- */
								color: $white;
							}
						}
					}

					a,
					button:not(.form-button) {
						color: $white;
						border: 1px solid $lightblue2;
						border-radius: 3rem;
						border-top-left-radius: 0;
						font-family: $font-nimbus-extended;
						font-weight: $font-weight-base;
						position: relative;
						padding-top: .75rem;
						padding-bottom: .75rem;
						min-width: 17rem;
						display: inline-flex;
						align-items: center;
						text-transform: uppercase;
						cursor: pointer;
						border-radius: 1.5rem;
						border-top-left-radius: 0;
						border-color: $white;
						width: 100%;

						@include fluid-margin(top,5);
						@include fluid(padding-left, crunch-spacer(4), crunch-spacer(5));
						@include fluid(padding-right, crunch-spacer(6), crunch-spacer(7));
						@extend %font-size-14,
						%letter-spacing-100,
						%single-transition;

						&::before {
							content: "";
							position: absolute;
							right: 1.875rem;
							width: 1rem;
							height: 1rem;

							@extend %single-transition;
						}

						&::before {
							position: absolute;
							background: url("../img/icon__chevron-down--white.svg") no-repeat center;
							background-size: contain;
							transform: rotate(-90deg);
							opacity: 1;
							z-index: 1;
						}

						&:hover,
						&:focus {
							color: $mediumblue;
							border-color: $mediumblue;

							&::before {
								background-image: url("../img/icon__chevron-down--mediumblue.svg");
							}
						}
					}

					&.current-menu-item,
					&.current_page_item {
						a {
							color: $mediumblue;
							border-color: $mediumblue;

							&::before {
								background-image: url("../img/icon__chevron-down--mediumblue.svg");
							}
						}
					}
				}

				&.external {
					a {
						&::after {
							content: "";
							display: inline-block;
							margin-left: .25rem;
							width: .65rem;
							height: .65rem;
							background: url("../img/icon__external.svg") center no-repeat;
							background-size: contain;
						}

						&:hover,
						&:focus {
							&::after {
								background-image: url("../img/icon__external--dark.svg");
							}
						}
					}
				}
			}

			.navigation-secondary {
				.menu-item {
					position: relative;
					margin-bottom: .5rem;
					color: $darkerblue;
					text-align: left;

					&::after {
						content: "|";

						@include fluid(padding-x, .5rem, .75rem);
					}

					&:last-child {
						&::after {
							display: none;
						}
					}

					a {
						border-bottom: 1px solid transparent;
						color: $darkerblue;
						text-align: left;
						font-weight: $font-weight-light;
						line-height: 1.7;

						@extend %font-size-14;

						&:hover,
						&:focus {
							color: $white;
						}
					}
				}

				&__copyrights {
					color: $darkerblue;
					font-weight: $font-weight-light;
					line-height: 1.7;

					@extend %font-size-14;
				}
			}

			&__checkbox {
				display: none;

				&:checked + .mobile-navigation__button {

					&::before {
						background-color: $white;
						transform: translate(-50%, -50%);
					}

					span {
						background: $lightblue2;
						@extend %single-transition;

						&:nth-of-type(1) {
							transform: translate(-50%,-50%) rotate(-90deg);
							transform: translateX(0px) translateY(5px) rotate(45deg)
						}

						&:nth-of-type(2) {
							opacity: 0;
						}
						&:nth-of-type(3) {
							transform: translateX(0px) translateY(-7px) rotate(-45deg)
						}
					}
				}
				&:checked ~ .mobile-navigation__menu-wrapper {
					transform: translateX(-50%) scaleY(1);
				}
			}
		}

		.toggles {
			border-radius: 4rem;
			background: $mediumblue;

			.toggle-view {
				background: transparent;
				border: 0;
				outline:none;

				@include fluid((
					width: (
						min: .75,
						max: 3rem,
					),
					height: (
						min: .75,
						max: 3rem,
					),
				));

				&:focus {
					outline:none;
				}

				&--list {
					background: url(../img/icon-list.png) no-repeat center right;
					background-size: auto 100%;

					&.active,
					&:hover {
						background: url(../img/icon-list-active.png) no-repeat center right;
						background-size: auto 100%;
						display:inline-block;
					}

					&.hidden {
						display:none;
					}
				}

				&--network {
					background: url(../img/icon-network.png) no-repeat center center;
					background-size:100% auto;
					position:relative;
					z-index:1;

					&.active,
					&:hover {
						background: url(../img/icon-network-active.png) no-repeat center center;
						background-size:100% auto;
						display:inline-block;
					}

					&.hidden {
						display:none;
					}
				}
			}
		}

		&--dark {
			.hero-section__logo {
				img {
					filter:invert(0);

					@extend %single-transition;
				}

				&--white {
					img {
						filter:invert(100%);
					}
				}
			}

			.mobile-navigation {
				&__button {
					&::before {
						background: $lightgray;
					}
				}

			}

			.primary-navigation {
				.menu-item {
					a {
						color: $darkbluegray !important;

						&:hover {
							color: $lightblue2 !important;
						}

						&:focus {
							color: $darkbluegray !important;
							border-color: $lightblue2 !important;
						}
					}

					&.current-menu-item,
					&.current_page_item {
						a {
							border-color: $lightblue2;
						}
					}
				}
			}
		}
	}
