/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~ Bootstrap Variables ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    /* ~~~~~ Color system ~~~~~ */

        $darkblue: #090e2e;
        $mediumblue:#292d4a;
        $mediumblue2: #1872a9;
        $lightblue: #00bdf9;
        $lighterblue: #71ddff;
        $midblue: #0485b5;
        $gray: #565656;
        $bluegray: #8f97ac;
        $darkgray: #535c71;
        $darkbluegray: #535c71;
        $lightblue2: #1e7f98;
        $lightblue3: #51c0dc;
        $lightgray: #f6f6f6;
        $darkerblue: #1d2034;

        $theme-colors: (
            // "darkblue": $darkblue,
            // "mediumblue": $mediumblue,
            // "mediumblue2": $mediumblue2,
            // "lightblue": $lightblue,
            // "lighterblue": $lighterblue,
            // "midblue": $midblue,
            // "gray": $gray,
            // "bluegray": $bluegray,
             "darkgray": $darkgray,
            // "darkbluegray": $darkbluegray
        );

        $theme-colors: map-remove($theme-colors, "success", "info", "warning", "danger", "light", "dark");

        $body-bg: $white !important;
        $body-color: $darkbluegray;


    /* ~~~~~ Grid ~~~~~ */

        $grid-breakpoints: (
            xs: 0,
            sm: 576px,
            md: 768px,
            lg: 992px,
            xl: 1200px,
            xxl: 1500px
        );

        $container-max-widths: (
            sm: 540px,
            md: 720px,
            lg: 960px,
            xl: 1140px,
            xxl: 1440px
        );

        // $grid-columns: 12;
        //$grid-gutter-width: 40px;


    /* ~~~~~ Fonts ~~~~~ */

        $font-family-base: "Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

        $font-nimbus: nimbus-sans, "Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        $font-nimbus-extended: nimbus-sans-extended, nimbus-sans, "Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
