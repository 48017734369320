/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Cookie Notice Bar Styles ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	#cookie-notice {
		opacity: 0;
		bottom: -50%;

		.admin-bar & {
		    margin-bottom: $mobile-wp-admin-bar-height;

		    @media screen and (min-width: rem(783px)) {
		        margin-bottom: $desktop-wp-admin-bar-height;
		    }
		}

	    .cookie-notice-container {
	    	border-top: 1px solid $lightblue3;

	        #cn-notice-text {
	            font-family: $font-family-base;
	            font-weight: $font-weight-base;
	            display: block;
	            margin-bottom: 0;
	            font-size: .875rem;
	        }

	        .cn-button {
	            box-shadow: none;
	            border: 1px solid $lightblue2;
	            color: $lightblue2;
	            border-radius: 3rem;
	            border-top-left-radius: 0;
	            font-weight: $font-weight-base;
	            font-family: $font-nimbus;
	            position: relative;
	            padding-top: .5rem;
	            padding-bottom: .5rem;
	            display: inline-flex;
	            align-items: center;
	            text-transform: uppercase;
	            cursor: pointer;
	            border-radius: 1.5rem;
	            border-top-left-radius: 0;

	            @include fluid-margin(top,5);
	            @include fluid-padding( x, 4);
	            @extend %font-size-12,
			            %letter-spacing-100,
			            %single-transition;

				&:hover,
				&:focus {
					color: $lightblue;
					border-color: $lightblue;
				}
	        }
	    }
	}

	.page,
	.single {
		#cookie-notice {
			opacity: 1;
			bottom: auto;
		}
	}