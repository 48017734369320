/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Network Hover Card styles ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	.network-hover {
		position: fixed;
		left: -999px;
		top: -999px;
		width: 18.75rem;
		width: 250px;
		transition:opacity .35s ease, transform .7s ease;
		opacity:0;
		transform:translateY(10px);
		&.animate-in {
			transform:translate(0);
			opacity:1;
		}

		.result {
			// min-height: 20rem;
			width:100%;
			border-radius:20px;
			border-top-left-radius: 0;
			border-top-right-radius: 75px;
			overflow: hidden;

			&.notchedLeftCorner {
				border-top-left-radius: 75px;
				border-top-right-radius: 0;
			}

			&__content-wrapper {
				background: #fff;
				// height: 15rem;
				min-height: 100%;
			}

			img {
				max-width: 100%;
				width: 100%;
				display: block;
				object-fit: cover;
				margin: 0;

				@include fluid(min-height, 8rem, 10rem);
			}

			.post-image-wrapper {
				display: block;
				background: $lightgray;
			}

			span {
				color: $darkgray;
				color:#8f97ac;
				text-align: left;
				line-height:1.5;
				// display: block;
			}

			.tag {
				display:block;

				@extend %font-size-14,
						%font-nimbus-extended;
			}

			&__title {
				color: $darkgray;
				color:#8f97ac;

				@extend %font-size-17;
				@include fluid-margin( bottom, 2);
			}

			&__arrow {
				align-self: flex-end;
				// position: absolute;
				margin-bottom: .5rem;
				margin-right: .5rem;

				svg {
					width: .75rem;
					height: auto;
					display: block;
				}
			}
		}
	}
