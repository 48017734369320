/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Chosen Select ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	.nf-form-content {
		.liststate-container.list-container {
			.nf-field {
				position: relative;
				z-index: 3;
			}
		}

		.list-select-wrap {
			.nf-field-element {
				> div:last-child {
					display: none;
				}
			}
		}

		.chosen-container {
			border:1px solid #0485b5 !important;
			background: #f7f7f7 !important;

			.chosen-drop {
			    position: absolute !important;
			    margin-top: -1px !important;
			    padding: 0 !important;
			    height: auto !important;
			    left: -1px !important;
			    z-index: 10 !important;
			    top: 100% !important;
			    border:1px solid #0485b5 !important;
			    background: #f7f7f7 !important;
			    -ms-overflow-style: none;  // IE 10+
			    overflow: -moz-scrollbars-none !important;  // Firefox

			    &::-webkit-scrollbar {
			        width: 0 !important;
			        display: none;
			    }

			    .chosen-results {
			    	-ms-overflow-style: none;  // IE 10+
			    	overflow: -moz-scrollbars-none !important;  // Firefox

			    	&::-webkit-scrollbar {
			    	    width: 0 !important;
			    	    display: none;
			    	}

			    	li {
			    		color: #787878;

			    		&::before {
			    			display: none;
			    		}

			    		&:first-child {
			    			display: none;
			    		}
			    	}
			    }
			}

			.chosen-single>div {
				position: absolute !important;
				right: 0 !important;
				top: 50% !important;
				left: auto !important;
				padding: 0 !important;
				height: .625rem !important;
			}
		}
	}

	.nf-form-content .list-select-wrap>div div:after {
		display: none;
	}

	.nf-form-content .list-select-wrap>div div {
		position: relative !important;
	}

	.chosen-container {
		width: 100% !important;
		position: relative !important;
		background: transparent;
		border:1px solid $lightblue3;
		border-radius: 1.75rem !important;
		border-top-left-radius: 0 !important;
		padding: 1rem 1.25rem .75rem 1.5rem !important;
		text-align: left !important;
		min-height: 3.125rem !important;
		display: block;

		@extend %single-transition;

		&:hover {
			background: $lightblue3 !important;

			.chosen-single {
				span {
					color: $mediumblue !important;
				}

				> div {
					background-image: url(../img/icon__chevron-down--white.svg) !important;
				}
			}
		}

		&.chosen-with-drop {
			.chosen-single {
				> div {
					transform: rotate(-180deg) translateY(50%);
				}
			}
		}

		&.chosen-container-active.chosen-with-drop {
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;

		    .chosen-single {
		        background: transparent;
		        border: none;
		        box-shadow: none !important;

		        &::after {
		            transform: translateY(-50%) rotate(180deg);
		        }
		    }
		}

		.chosen-single,
		.chosen-drop {
		    box-shadow: none !important;
		}

		.chosen-single {
			height: auto !important;
			padding-left: 2px;
			display: table;
			border-radius: 0;
			width: 100%;
			position: relative;
			background: transparent;
			border: none;
			color: $white;
			font-family: $font-nimbus-extended;
			text-transform: uppercase;
			line-height: 1.125;

			@extend %font-size-13,
			        %letter-spacing-100,
			        %single-transition;

			span {
				text-overflow: unset;
				white-space: nowrap;

				@extend %single-transition;
			}

			> div {
				top: 50%;
				display: block;
				position: absolute !important;
				width: 1rem;
				height: .625rem;
				right: 0;
				background: url(../img/icon__chevron-down.svg) no-repeat !important;
				background-size: contain !important;
				background-position: center !important;
				transform: rotate(0) translateY(-50%);

				@extend %single-transition;

				b {
					display: none !important;
				}
			}
		}

		.chosen-drop {
		    background: $mediumblue;
		    border: 1px solid $lightblue3;
		    min-width: 8rem;
			left: -1px;
			width: calc(100% + 2px);
			border-radius: 1.75rem;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			text-align: left;

		    @extend %font-size-16;

		    .chosen-search {
		        display: none;
		    }

		    .chosen-results {
		        padding: crunch-spacer(2) 0 crunch-spacer(3) 0;
		        margin: 0;

		        li {
		            color: $bluegray;
		            padding-top: crunch-spacer(2);
		            padding-bottom: crunch-spacer(2);
		            white-space: nowrap;

		            @include fluid(padding-x, crunch-spacer(3), crunch-spacer(4));
		            @extend %single-transition;

		            &.highlighted,
		            &.result-selected {
		                background: transparent;
		            }

		            &.highlighted {
		                color: $lightblue3;
		            }

		            &.result-selected {
		                color: $white;
		                font-weight: $font-weight-medium;
		            }
		        }
		    }
		}
	}

	select.chosen-select {
		width: 100% !important;
		position: relative !important;
		background: transparent;
		border:1px solid $lightblue3;
		border-radius: 1.75rem !important;
		border-top-left-radius: 0 !important;
		padding: .75rem 1.25rem .75rem 1.5rem !important;
		text-align: left !important;
		min-height: 3.125rem !important;
		display: block;
		color: $white;
		font-family: $font-nimbus-extended;
		text-transform: uppercase;
		line-height: 1.125;
		outline: none !important;

		@extend %font-size-13,
		        %letter-spacing-100,
		        %single-transition;

		&:hover {
			background: $lightblue3 !important;
			color: $mediumblue !important;
		}
	}