/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Homepage List View styles ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

	#content-wrapper {
		@include fluid(min-height, calc(100vh - 4.75rem), calc(100vh - 12.75rem));
	}

	.admin-bar {
	    #content-wrapper {
	    	@include fluid(min-height, calc(100vh - 7.65rem), calc(100vh - 14.75rem));
	    }
	}

	.results-grid {
		@include media-breakpoint-up(md) {
			padding: 0;
			margin-top: 5.5rem;
			left: 41%;
		}

		@include media-breakpoint-up(lg) {
			left: 32.5%;
		}

		@include media-breakpoint-up(xl) {
			margin-top: 6rem;
			left: 25rem;
			max-width: calc(100% - 25rem);
			flex: 0 0 calc(100% - 25rem) !important;
		}

		@media (min-width:1200px) and (max-width:1300px) {
			left: 20rem;
			max-width: calc(100% - 20rem);
			flex: 0 0 calc(100% - 20rem) !important;
		}

		&.expanded {
			@include media-breakpoint-up(md) {
				max-width: 100%;
				width: 100%;
			}
		}

		.no-result-link {
			color: $lightblue3;

			&:hover,
			&:focus {
				color: $white;
			}
		}
	}

	.results-wrapper {
		padding-left: 0;
		padding-right: 0;

		&.team-archive-wrapper {
			.st-result__point-index {
				display: none !important;
			}
		}

		&:not(.team-archive-wrapper) {
			#st-results-container {
				.st-result {
					&__image-wrapper {
						display: none !important;

						@include media-breakpoint-up(sm) {
							display: block !important;
						}
					}
				}
			}
		}

		button {
			color:#fff;
			margin:0 5px;

			&.category {
				border:1px solid $lightblue;
				border-radius:30px;
				padding: 4px 12px;
			}
		}

		.list-view {
			display: none;
			right: 0;
			width: 100%;
			background: $darkblue;
			height:auto;
			overflow:hidden;
			max-height: unset;
			padding-top: 0;
			padding-left: 0;
			padding-right: 0;
			max-width: 100% !important;
			transform:translateX(150%);

			@include fluid(padding-left, .875rem, 1.375rem, $from: md);

			@include media-breakpoint-up(xxl) {
				padding-left: 3.375rem;
			}

			.list-view-controls {
				text-align: left;
				padding:0 20px;
				font-size:13px;

				.active {
					color:#fff;
				}

				.separator {
					margin: 0 10px;
					color:$gray;
				}

				.list-view-toggle {
					position:relative;
					top:auto;
					right:auto;
					padding:0;
					color:$lightblue;
				}
			}

			&.open {
				position: static !important;
				transform: translateX(0);
				display: block !important;
			}
		}

		&.expanded {
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			right: 0;
			margin-top: 0;

			@include media-breakpoint-up(md) {
				padding-left: 1rem !important;
				width: calc(100vw - 3rem);
				// position: absolute;
			}

			@include media-breakpoint-up(lg) {
				padding-left: 0 !important;
				width: calc(100vw - 5rem);
			}

			@include media-breakpoint-up(xl) {
				width: calc(100vw - 6rem);
			}

			@include media-breakpoint-up(xxl) {
				width: calc(100vw - 7.5rem);
			}

			.list-view {
				display: block !important;
				padding-left: 0 !important;
			}

			#st-results-container {
				.st-result {
					@include media-breakpoint-up(md) {
						flex-basis: calc(33.33% - 1.25rem) !important;
					}

					@include media-breakpoint-up(lg) {
						flex-basis: calc(25% - 1.25rem) !important;
					}

					@include media-breakpoint-up(xxl) {
						flex-basis: calc(20% - 1.25rem) !important;
					}
				}
			}
		}

		#st-results-container {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			align-items: stretch;

			&.opacity-50 {
				opacity:.5;
			}

			.st-result {
				flex-basis: 100%;
				margin: .625rem 0;
				overflow: hidden;
				padding: 0;
				float: left;
				border-radius: 20px;
				border-top-left-radius: 0;
				display: flex;
			    flex-direction: column;
			    align-items: normal;
			    height: 100%;
			    position:relative;

				@include fluid((
					min-height: (
						min: 20rem,
						max: 25rem,
						from: sm,
					),
					width: (
						min: .75,
						max: 17.25rem,
					),
				));

				@include media-breakpoint-up(sm) {
					border-top-right-radius: 75px;
					flex-basis: calc(50% - 1.25rem);
					margin: .625rem;
				}

				@include media-breakpoint-up(xl) {
					flex-basis: calc(33.33% - 1.25rem);
				}

				@media (min-width: 1745px) {
					flex-basis: calc(25% - 1.25rem);
				}
				position:relative;
				&::before {
					content: "";
					display: block;
					position: absolute;
					width: 100%;
					height: 50%;
					left: 0;
					bottom: 0;
					background: #fff;
					z-index: -1;
					border-radius: 20px;
				}

				&:hover,
				&:focus {
					img {
						transform: scale(1.1);
					}

					.st-result__arrow {
						svg {
							path {
								fill: $lighterblue !important;
							}
						}
					}

					.st-result__title {
						color: $lightblue;
					}
				}

				.post-image-wrapper {
					background: $lightgray;
					&[data-view="teampage"],
					&.headshot {
						background: transparent;
					}
					&.headshot {
						.headshot-wrapper {
							background-size:cover;
							background-position:center center;
							height:100%;
							-webkit-transition: all .2s ease-in-out;
							transition: all .2s ease-in-out;
							&:hover {
								transform: scale(1.1);
							}
							+ img {
								opacity:0;
							}
						}
					}
				}

				&.active {
					border-top-right-radius: 75px;

					.st-result__image-wrapper {
						display: block !important;
					}

					.st-result__point-index {
						display: none !important;
					}
				}

				&__point-index {
					display: block;
					width: 1.125rem;
					min-width: 1.125rem;
					height: 1.125rem;
					margin-right: 1rem;
					border-radius: 100%;
					background: $lightblue2;
					margin-top: .35rem;

					@include media-breakpoint-up(sm) {
						display: none !important;
					}
				}

				&__viewbox {
					height: 100%;
					display: block;

					> span {
						height: 100%;
					}
				}

				&__content-wrapper {
					background: $white;

					@include fluid(height, calc(100% - 10rem), calc(100% - 12rem));
					/*@include fluid(min-height, 10rem, 12.5rem);*/
					@include fluid(min-height, 200px, 225px);
				}

				a,
				span,
				button {
					color: $bluegray;
					text-align: left;
					display: block;
				}

				button:not(.st-result__image-wrapper) {
					color: $darkgray;
					-webkit-appearance: none;
					border: 0;
					padding: 0;
					margin: 0;
					margin-top: .25rem!important;

					@include fluid-margin( top, 2);

					&:hover,
					&:focus {
						color: $lightblue;
					}
				}

				&__image-wrapper {
					margin: 0;
					max-width: 100%;
					width: 100%;
					overflow: hidden;

					@include fluid(height, 10rem, 12rem);

					@include media-breakpoint-up(sm) {
						display: block !important;
					}

					img {
						display: block;
						object-fit: cover;
						width: 100%;
						height: 100%;
						transform: scale(1);

						@extend %single-transition;
					}
				}

				&__arrow {
					align-self: flex-end;

					&:hover,
					&:focus {
						svg {
							path {
								fill: $lighterblue !important;
							}
						}
					}

					svg {
						width: .75rem;
						height: 1.25rem;
						display: block;

						path {
							@extend %single-transition;
						}
					}
				}
			}
		}

		.st-page {
			.st-prev {
				float:left;
				color:#fff;
			}

			.st-next {
				float:right;
				color:#fff;
			}
		}
	}

	.no-results {
		.suggested-searches {
		    padding-left: 0;
		    padding-top:30px;
		    button {
		    	display:block;
		    	margin: 0 auto;
		    	color:$lightblue3;
		    }
		}
	}

	.scroll-page-to-top.scroll-to-top-link {
		position:fixed;
		bottom:15px;
		right:20px;
		padding:0;
		transition: all 1s ease;
		&.visible {
			opacity:1;
		}
	}

	body.logged-in {
		.scroll-page-to-top.scroll-to-top-link {
			bottom:30px;
		}
	}

/*.results-wrapper #st-results-container [data-url*="/team/"] .st-result__content-wrapper {
	min-height:50px;
}*/

.results-wrapper #st-results-container [data-browserisie="true"] {
	.post-image-wrapper {
		background: $lightgray;
		&.headshot{
			background: $lightgray;
		}
		img {
			opacity:0;
		}
		.headshot-wrapper {
			background-size:cover;
			background-position:center center;
			height:100%;
			-webkit-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
}

/*.results-wrapper #st-results-container .st-result__arrow {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
}*/